:root {
  --background: #4d4d4d;
  --sinixOrange: #f0661e;
  --linkAdminColor: lightgreen;
}

html * {
  font-family: 'Lato' !important;
}

.app {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .sidebar {
    width: 250px;
    height: 100vh;
    background: var(--background);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;

    .menu-toggle {
      display: none;
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: white;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }

    @media all and (max-width: 768px) {
      transform: translateX(-100%);
      width: 100%;
      max-width: 300px;
      position: fixed;
      height: 100vh;

      &.open {
        transform: translateX(0);
      }

      .menu-toggle {
        display: block;
      }
    }

    .logo {
      padding: 1rem;
      text-align: center;
      img {
        width: 140px;
        height: auto;
      }
      flex-shrink: 0;
    }

    .menu-items {
      padding: 0.5rem 0;
      flex: 1;
      overflow-y: auto;

      .menu-item {
        padding: 0.5rem 1rem;
        color: white;
        display: block;
        text-decoration: none;
        transition: all 0.2s ease;

        &:hover {
          background: var(--sinixOrange);
        }
      }

      .admin-item {
        color: var(--linkAdminColor);
        font-weight: bold;

        &:hover {
          background: var(--linkAdminColor);
          color: var(--background);
        }
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 3px;
      }
    }
  }

  .main-content {
    margin-left: 250px;
    flex: 1;
    padding: 2rem;
    width: calc(100% - 250px);
    overflow-x: hidden;

    @media all and (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    .navigation {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0.5rem 2rem;
      background: var(--background);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .logo {
      background: var(--background);
      padding: 0.5rem 0;
      img {
        width: 160px;
        height: auto;
        vertical-align: middle;
      }
    }

    .link {
      text-decoration: none;
      display: inline-block;
      padding: 0.8em 1.2em;
      color: white;
      border-radius: 4px;
      transition: all 0.2s ease;
      margin: 0 0.2em;

      &:hover {
        background: var(--sinixOrange);
        transform: translateY(-1px);
      }
    }

    .admin-link {
      @extend .link;
      color: var(--linkAdminColor);
      font-weight: bold;
      border: 1px solid var(--linkAdminColor);

      &:hover {
        background: var(--linkAdminColor);
        color: var(--background);
      }
    }

    @media all and (max-width: 800px) {
      .navigation {
        padding: 0.5rem 1rem;
      }
    }

    @media all and (max-width: 600px) {
      .navigation {
        flex-flow: column wrap;
        padding: 0;

        a {
          text-align: center;
          padding: 12px 20px;
          width: 100%;
          border-radius: 0;
          margin: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .navigation li:last-of-type a {
          border-bottom: none;
        }
      }
    }

    .custom-tooltip {
      background-color: lightgray;
      border: none;
      padding: 5px;
      .title {
        width: 10px;
      }
      .val {
        text-align: right;
      }
    }

    .tooltip-highlight {
      text-decoration: green underline dotted;
    }

    .employee {
      padding: 5px;
      color: black;
      font-size: 16px;
      margin-left: auto;
      margin-right: auto;
      .title {
        font-weight: bold;
        text-align: right;
      }
      .val {
        text-align: left;
      }
    }
    .table-header {
      font-size: 16px;
      font-weight: bold;
    }
    .table-new {
      font-size: 18px;
      font-weight: bold;
      color: green;
    }

    .analytics-heading {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
    .analytics-text {
      text-align: center;
    }
    .slack {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    @media all and (max-width: 768px) {
      &.show {
        display: block;
      }
    }
  }

  .mobile-menu-toggle {
    display: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1001;
    background: var(--background);
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;

    @media all and (max-width: 768px) {
      display: block;
    }
  }
}
